const ItemsReducer = (state = [], action) => {
  
 
  switch (action.type) {
    case 'SELECT_ITEMS':
      return action.items
    default:
      return state
  }
  
}

export default ItemsReducer
